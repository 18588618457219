import classNames from 'classnames';

import type { FCC } from '@rikstv/play-common/src/utils/types/typeUtils';

import styles from './Section.module.css';

export type SectionType = 'light' | 'dark' | 'very-dark' | 'transparent';

interface Props {
  className?: string;
  id?: string;
  type: SectionType;
  fullHeight?: boolean;
  noPaddingTop?: boolean;
  noPaddingBottom?: boolean;
}

export const Section: FCC<Props> = ({
  id,
  type,
  children,
  fullHeight,
  noPaddingTop,
  noPaddingBottom,
  className,
  ...restProps
}) => {
  const typeClassName = styles[type === 'very-dark' ? 'veryDark' : type];

  const containerClassName = classNames(
    styles.container,
    typeClassName,
    {
      [styles.fullHeight]: fullHeight,
      [styles.noPaddingTop]: noPaddingTop,
      [styles.noPaddingBottom]: noPaddingBottom,
    },
    className
  );

  return (
    <section className={containerClassName} id={id} {...restProps}>
      {children}
    </section>
  );
};
